import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../webapi.service';
import { Router,ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-client-mis',
  templateUrl: './client-mis.component.html',
  styleUrls: ['./client-mis.component.scss']
})
export class ClientMisComponent implements OnInit {
  processList:any=[];
  filterData:any={};
  checkList:any=[];
  responseData:any;
  clickedData:any=[];
  reportUrl:any;
  processListSelect: any=[];

  constructor(private webapiservice: WebapiService,private router:Router) {
    this.reportUrl = this.webapiservice.reportUrl;
  }

  ngOnInit(): void {
    let clientId = sessionStorage.getItem('loginId');
    this.filterData.clientId = clientId;
    this.getProcessByClient(clientId);


  }

  getCount(status: string, color: string): number {
    const key = `${status.toLowerCase()}${color}Count`;
    return this.responseData?.[key] ?? 0; // Return 0 if the count is undefined
  }

  getCheckCount(checkName: string, status: string): number {
    // Safely navigate through responseData and return the appropriate count or 0 if not found
    return this.responseData?.checknameCounts?.[checkName]?.[status] ?? 0;
  }

  getProcessByClient(id:any){
    this.webapiservice.getUserById(id).subscribe((res:any)=>{
      //  console.log(res);
      if(res.user){
        this.processListSelect = res?.user?.processList;
        this.processList = res?.user?.processList;
        this.filterData.process = res?.user?.processList[0]?.process;
        this.onFilterData(this.filterData);
      }
      else{
        this.onFilterData(this.filterData);
        this.processList = [];
       }
    });
  }

  getProcessChecks(name:any){
  //  console.log(e.target.value,'--');
   this.processList = this.processListSelect.filter(prd => prd.process == name);
   console.log(this.processList,'--');

  }

  onFilterData(data:any){
    this.getProcessChecks(data.process);
    this.webapiservice.getClientMisCount(data).subscribe((res:any)=>{
       if(res.status == 'ok'){
   this.responseData = res?.data;
       }
       else{
        this.responseData = {}
       }
    });
  }

  updateStatus(e:any,id:any){
    let conf = confirm('Are you sure you want to update status??');
    if(conf){
      let val = {
        status: e.target.value,
        appId:id
       }
       this.webapiservice.updateAppStatus(val).subscribe((res:any)=>{
        if(res.status == 'ok'){
      alert(res.msg);
      location.reload();
        }
        else{
alert('Error updating Status..')
        }
     });
    }
  }

  getApplicationByClick(count:any,color:any,status:any){
    if(count > 0){
    this.filterData.color = color;
    this.filterData.status = status;
    this.webapiservice.getClientMisData(this.filterData).subscribe((res:any)=>{
     console.log(res);
     if(res.status == 'ok'){
 this.clickedData = res.data;
     }
     else{
      this.responseData = {}
     }
    });
    }
    else{
     alert('Count is less than 1');
    }

   }

   getApplicationByClickV2(count:any,chkName:any,chkStatus:any){
    if(count > 0){
    this.filterData.checkName = chkName;
    this.filterData.checkStatus = chkStatus;
    this.webapiservice.getClientMisChecksData(this.filterData).subscribe((res:any)=>{
     console.log(res);
     if(res.status == 'ok'){
 this.clickedData = res.data;
     }
     else{
      this.responseData = {}
     }
    });
    }
    else{
     alert('Count is less than 1');
    }

   }

   getExcelDownload(data:any){
    this.webapiservice.exportMisReport(data).subscribe((res: any) => {
      console.log(res);
    const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
     FileSaver.saveAs(blob, 'MisReport.xlsx');
    });
   }




}
