import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebapiService } from '../webapi.service';


@Component({
  selector: 'app-report-log',
  templateUrl: './report-log.component.html',
  styleUrls: ['./report-log.component.scss']
})
export class ReportLogComponent implements OnInit {
  appId: any;
  filter: any={
    pageNo:1,
    size:10,
    appId:''
}
reportList:any=[];
total:any=0;
p:any=1;
loginType:any;
loginId:any='';
reportEditData:any={};
  constructor(private webapiservice:WebapiService,
      private router:Router,
      private activatedRoute: ActivatedRoute
      ){ }

  ngOnInit(): void {
    this.appId = this.activatedRoute.snapshot.paramMap.get("id");
    this.loginType = sessionStorage.getItem('type');
    this.loginId = sessionStorage.getItem('loginId');
    // console.log(this.appId);
    if(this.appId){
      this.getReportLogById(this.appId);
    }

  }

  getReportLogById(id:any){
    this.filter.appId = id;
    this.webapiservice.getAllReportLogByAppId(this.filter).subscribe((res:any)=>{
      // console.log(res);
      if(res.total > 0){
        this.reportList = res.data;
        this.total = res.total;
      }
      else{
        this.reportList = [];
        this.total = 0;
      }

  });
}

onTableDataChange(event: any) {
  this.filter.pageNo = event;
  this.getReportLogById(this.filter)
  this.p = event;
}

updateReportData(data:any,id:any){
  data._id = id;
  data.editedBy = this.loginId;

  this.webapiservice.createReportLog(data).subscribe((res: any) => {
    // console.log(res);
    if(res.status == "ok"){
      alert(res.msg);
      location.reload();
    }
    else{
      alert(res.msg);
    }

  });
}

formatDateV2(dateString:any){
  const date = new Date(dateString);

  // Convert to IST (UTC + 5:30)
  const istOffset = 5.5 * 60 * 60 * 1000; // Offset in milliseconds
  const istDate = new Date(date.getTime() - istOffset);

  // Format the date parts
  const day = String(istDate.getDate()).padStart(2, '0');
  const month = String(istDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = istDate.getFullYear();
  const hours = String(istDate.getHours()).padStart(2, '0');
  const minutes = String(istDate.getMinutes()).padStart(2, '0');

  // Return formatted string
  return `${day}/${month}/${year}, ${hours}:${minutes}`;
}
}
