import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../webapi.service';
import * as moment from 'moment';
// import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  dataCount: any;
  barChartData: any = [{}];
  // barChartData = [{
  //   label: 'Applications',
  //   data: [0,0,0,0,0,0,0,0,0,0,0,0],
  //   borderWidth: 1,
  //   fill: false
  // }];
  barChartLabels: any = [];
  barChartOptions: any = {};
  barChartColors: any = [];
  doughnutPieChartData: any = [];
  doughnutPieChartLabels: any = [];
  doughnutPieChartOptions: any = {};
  doughnutPieChartColors: any = [];
  countList: any;
  loginType: any;
  teamName: any;
  clientAppCount:any=[];
  clientAppCountV2:any=[];
  loginId: any;
  filter:any={}
  clientList: any = [];
  todayCount = new Date();
  tomorrow = new Date(this.todayCount.setDate(this.todayCount.getDate() - 7));
  filterCount:any={
    "to-actionDate": moment(new Date()).format('yyyy-MM-DD') + 'T23:59:59',
    "from-actionDate": moment(this.tomorrow).format('yyyy-MM-DD') + 'T00:00:00',
  }
  fromDate: any;
  toDate: any;
  clientId:any;
  spocList:any=[];

  constructor(private webapiService: WebapiService,
) { }

  ngOnInit() {
    this.loginType = sessionStorage.getItem('type');
    this.teamName = sessionStorage.getItem('teamName');
    this.loginId = sessionStorage.getItem('loginId');

    this.fromDate = moment(this.tomorrow).format('yyyy-MM-DD')
    this.toDate =moment(new Date()).format('yyyy-MM-DD')
    console.log(this.fromDate);

    if (this.loginType == 1) {
      this.getAllCountData();
      this.getMonthWiseData();
      this.getApplicationCountByStatus();
      this.getAllClient();
    }
    else if(this.loginType == 4 && this.teamName == "Case Initiation Team"){
      this.getAllCountDataInit();
    }
    else if(this.loginType == 4 && this.teamName == "Processing Team"){
      this.getAllCountDataV2();
    }
    else if(this.loginType ==3){
      this.filter.clientId = this.loginId;
      this.getAllSpocByClientWithTaskLog(this.loginId);
      this.getAllCountData();
      this.getMonthWiseData();
      this.getApplicationCountByStatus();
    }

    else if(this.loginType == 6){
      let process = sessionStorage.getItem('assignProcess');
      this.filter.assignProcess = process;
      this.getAllCountData();
      this.getMonthWiseData();
      this.getApplicationCountByStatus();
    }
    else if(this.loginType == 7){
      this.filter.assignTo = this.loginId;
      this.getAllCountData();
      this.getMonthWiseData();
      this.getApplicationCountByStatus();
    }
    this.barChartLabels = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    this.barChartOptions = {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      },
      legend: {
        display: false
      },
      elements: {
        point: {
          radius: 0
        }
      }
    };

    this.barChartColors = [
      {
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255,99,132,1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ]
      }
    ];

    this.doughnutPieChartLabels = ['NEW', 'INPROGRSS', 'HOLD', 'COMPLETED', 'STOPPED'];

    this.doughnutPieChartOptions = {
      responsive: true,
      animation: {
        animateScale: true,
        animateRotate: true
      }
    };

    this.doughnutPieChartColors = [
      {
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255,99,132,1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ]
      }
    ];

    // console.log(this.clientAppCount,'----');

this.getClient();
  }

  getAllSpocByClientWithTaskLog(id:any){
  let val = {
    clientId:id
  }
  this.webapiService.getAllSpocByClientWithTaskLog(val).subscribe((res: any) => {
    console.log(res,'--');
    if(res.data){
      this.spocList = res.data;
    }
    else{
      this.spocList = [];
    }
  });
  }

  getApplicationCountByStatus() {
    this.webapiService.getApplicationCountByStatus(this.filter).subscribe((res: any) => {
      // console.log(res);
      if(res.data[0]?.count_per_field){
        this.countList = res.data[0]?.count_per_field;
      }
      this.doughnutPieChartData = [{
        label: 'Application Status',
      }];

      let months = ['NEW', 'INPROGRESS', 'HOLD', 'COMPLETED', 'STOPPED'];
      let data = [0, 0, 0, 0, 0,0];
      if(res.data[0]?.count_per_field){
        for (let i = 0; i < months.length; i++) {
          const index = months.findIndex(lable => lable == this.countList[i]?.k)
          if (index >= 0) {
            data[index] = this.countList[i]?.v;
          }
        }
      }
      this.doughnutPieChartData[0].data = data;

    })
  }

  getAllCountData() {
    this.webapiService.getAllCountData(this.filter).subscribe((res: any) => {
      //  console.log(res);
      this.dataCount = res;
    })
  }
  getAllCountDataInit() {
    this.webapiService.getAllCountDataInit().subscribe((res: any) => {
      //  console.log(res);
      this.dataCount = res;
    })
  }
  getAllCountDataV2() {
    this.webapiService.getAllCountDataV2().subscribe((res: any) => {
      //  console.log(res);
      this.dataCount = res;
    })
  }

  getAllClient(){
    this.clientAppCount = []
    this.clientAppCountV2 = []

    this.webapiService.getAllClient().subscribe((res: any) => {
      //  console.log(res);
       for (const it of res.user) {
        if(this.clientId){

          if(this.clientId==it._id){
            this.getClientCount(it._id,it.name,"INPROGRESS");
            this.getClientCountV2(it._id,it.name,"COMPLETED");
          }
        }else{
          this.getClientCount(it._id,it.name,"INPROGRESS");
          this.getClientCountV2(it._id,it.name,"COMPLETED");
        }
        // this.filterCount.id = it._id
        // this.filterCount.name = it.name

        //  this.getClientCountV2(it._id,it.name,"COMPLETED");
       }
    })
  }

  getClientCount(id:any,name:any,status:any){

    let val={
      id:id,
      status:status,
      "to-actionDate": moment(this.toDate).format('yyyy-MM-DD') + 'T23:59:59',
      "from-actionDate": moment(this.fromDate).format('yyyy-MM-DD') + 'T00:00:00',
    }
    this.webapiService.getClientCount(val).subscribe((res: any) => {
      // console.log(res);
      if(res){
        this.clientAppCount.push({
          clientName:name,
          count:res.count,
          tatOut:res.tatOut,
          tatToday:res.tatToday,
          tatIn:res.tatIn
        })
      }
   })
  }
  getClientCountV2(id:any,name:any,status:any){
    let val={
      id:id,
      status:status,
      "to-actionDate": moment(this.toDate).format('yyyy-MM-DD') + 'T23:59:59',
      "from-actionDate": moment(this.fromDate).format('yyyy-MM-DD') + 'T00:00:00',
    }

    this.webapiService.getClientCount(val).subscribe((res: any) => {
      // console.log(res);
      if(res){
        this.clientAppCountV2.push({
          clientName:name,
          count:res.count,
          tatOut:res.tatOut,
          tatToday:res.tatToday,
          tatIn:res.tatIn,
          percent:res.percentage
        })
      }
   })
  }

  getMonthWiseData() {
    let val = {
      year: moment().year()
    }
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    this.barChartData = [{
      label: 'Application',
      borderWidth: 1,
      fill: false
    }];
    this.webapiService.getMonthWiseData(this.filter).subscribe((res: any) => {
      let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < months.length; i++) {
        const index = months.findIndex(lable => lable == moment.months(res[i]?._id - 1))
        if (index >= 0) {
          data[index] = res[i]?.count;
        }
      }
      console.log(data);

      this.barChartData[0].data = data;
    })
  }
  getClient() {
    this.webapiService.getAllClient().subscribe((res: any) => {
      this.clientList = res.user;
    });
  }
}
