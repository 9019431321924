import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebapiService } from '../webapi.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-insuf',
  templateUrl: './insuf.component.html',
  styleUrls: ['./insuf.component.scss']
})
export class InsufComponent implements OnInit {
  filter: any={
    pageNo:1,
    size:10,
    clientId:'',
    insufStatus:'INSUF'
}
insufList:any=[];
total:any=0;
p:any=1;
loginType:any;
loginId:any='';
filterData:any={};
insufCheckListArr:any=[];
insufUpdateRemarkData:any={};

constructor(private webapiservice:WebapiService,
  private router:Router,
  private activatedRoute: ActivatedRoute,
  private modalService: NgbModal
  ){ }

  ngOnInit(): void {
    this.loginType = sessionStorage.getItem('type');
    this.loginId = sessionStorage.getItem('loginId');

    if(this.loginType == 3){
      this.filter.clientId = this.loginId;
    }
    else{
      this.filter.clientId = '';
    }
    // console.log(this.appId);
   this.getInsuffApplications(this.filter);
  }

  getInsuffApplications(filter:any){
    this.webapiservice.getInsuffApplications(filter).subscribe((res:any)=>{
      console.log(res);
      if(res.total > 0){
        this.insufList = res.data;
        this.total = res.total;
      }
      else{
        this.insufList = [];
        this.total = 0;
      }
  });
}

openSmallModalV2( statusMContent,data) {
  this.insufCheckListArr = data.insuffLog;
  this.modalService.open( statusMContent, { size : 'sm' } );
  console.log(this.insufCheckListArr,'--');
  
}

onChangeRemark(e:any,id:any){
  if(e.target.value){
    this.insufUpdateRemarkData.updateRemark = e.target.value;
    this.insufUpdateRemarkData._id = id;
   }
   else{
    this.insufUpdateRemarkData.updateRemark = '';
   }
}

onFilterData(data:any){
  // console.log(data,'--');
  this.filter.from = data.from;
  this.filter.to = data.to;
  this.getInsuffApplications(this.filter);

  
}

updateInsufRemark(data:any){
  data.status = "INSUF-CLEAR"
  data.insufClearBy = this.loginId;
  if(!data.updateRemark){
    alert('Remark Cannot be Empty');
    return
  }
  this.webapiservice.insertInsufLog(data).subscribe((res:any)=>{
    if(res.status == "ok"){
      alert('Insuf Remark updated!!');
      location.reload();
    }
    else{
      alert('Something went wrong!!');
    }
});
  
}
  onTableDataChange(event: any) {
    this.filter.pageNo = event;
    this.getInsuffApplications(this.filter)
    this.p = event;
  }

  formatDateV2(dateString:any){
    const date = new Date(dateString);

    // Convert to IST (UTC + 5:30)
    const istOffset = 5.5 * 60 * 60 * 1000; // Offset in milliseconds
    const istDate = new Date(date.getTime() - istOffset);

    // Format the date parts
    const day = String(istDate.getDate()).padStart(2, '0');
    const month = String(istDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = istDate.getFullYear();
    const hours = String(istDate.getHours()).padStart(2, '0');
    const minutes = String(istDate.getMinutes()).padStart(2, '0');

    // Return formatted string
    // return `${day}/${month}/${year}, ${hours}:${minutes}`;
    return `${day}/${month}/${year}`;
  }
  


}
