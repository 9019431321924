import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../webapi.service';
import { Router,ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {
  exportData:any={};
  exportBillData:any={};
  loginType:any
  clientList: any;
  processList: any;
  constructor(private WebApiService: WebapiService, private router: Router, private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.getClient();
    this.loginType = sessionStorage.getItem('type');
  }
  getClient() {
    this.WebApiService.getAllClient().subscribe((res: any) => {
      console.log(res);
      this.clientList = res.user;
    });
  }

  getProcessByClientV2(e:any){
    let id = e.target.value;
    setTimeout(()=>{
      let arr = this.clientList.filter(e=> e._id == id);
      this.processList = arr[0].processList;
    },1000)
  }

  exportAppData(data:any){
    if(!data.clientId){
      alert('Please Select Client');
      return;
    }
    this.WebApiService.exportFile(data).subscribe((res: any) => {
      console.log(res);
    const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
     FileSaver.saveAs(blob, 'Export.xlsx');
     this.exportData = {};
    });
  }
  exportBillingData(data:any){
    if(!data.clientId){
      alert('Please Select Client');
      return;
    }if(!data.process){
      alert('Please Select Process');
      return;
    }
    this.WebApiService.exportBillData(data).subscribe((res: any) => {
      console.log(res);
    const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
     FileSaver.saveAs(blob, 'ExportBillData.xlsx');
     this.exportBillData = {};
    });
  }
}
