import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../webapi.service';

@Component({
  selector: 'app-task-log',
  templateUrl: './task-log.component.html',
  styleUrls: ['./task-log.component.scss']
})
export class TaskLogComponent implements OnInit {

  filter: any={
    pageNo:1,
    size:10,
    assignMember:'',
    dateFrom:'',
    dateTo:'',
    added_by:''
}
p:any=1;
logList:any=[];
total:any=0;
imageUrl:any='';
formData:any={};
  userList: any =[];
  loginType:any;
  loginId:any;

  constructor(private webapiservice:WebapiService,) { }

  ngOnInit(): void {
    this.imageUrl = this.webapiservice.imageUrl;
    this.loginType = sessionStorage.getItem('type');
    this.loginId = sessionStorage.getItem('loginId');
    console.log(this.loginType,'--');

    if(this.loginType == 6){
      this.filter.added_by = this.loginId;
      this.getUserByType(7);
    }
    if(this.loginType == 3){
      this.filter.added_by = this.loginId;
      this.getUserByType(6);
    }
    if(this.loginType == 1){
      this.getUserByType('SPOCTM');
    }

    this.getAllTaskLog(this.filter);
  }


  getAllTaskLog(filter:any){
    this.webapiservice.getAllTaskLog(filter).subscribe((res:any)=>{
    console.log(res);
    if(res.total > 0){
      this.logList = res.data;
      this.total = res.total;
    }
    else{
      this.logList = [];
      this.total = 0;
    }
  })
  }

  filterData(data:any){
    if(data.assignMember){
      this.filter.assignMember = data.assignMember;
    }
    if(data.dateFrom && data.dateTo){
      this.filter.dateFrom = data.dateFrom;
      this.filter.dateTo = data.dateTo;
    }

    this.getAllTaskLog(this.filter);
  }

  getUserByType(id:any){
    this.webapiservice.getUserByType(id).subscribe((res:any)=>{
      // console.log(res);
      this.userList = res.data
    });
  }

  onTableDataChange(event: any) {
    this.filter.pageNo = event;
    this.getAllTaskLog(this.filter)
    this.p = event;
}

formatDateV2(dateString:any){
  const date = new Date(dateString);

  // Convert to IST (UTC + 5:30)
  const istOffset = 5.5 * 60 * 60 * 1000; // Offset in milliseconds
  const istDate = new Date(date.getTime() - istOffset);

  // Format the date parts
  const day = String(istDate.getDate()).padStart(2, '0');
  const month = String(istDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = istDate.getFullYear();
  const hours = String(istDate.getHours()).padStart(2, '0');
  const minutes = String(istDate.getMinutes()).padStart(2, '0');

  // Return formatted string
  return `${day}/${month}/${year}, ${hours}:${minutes}`;
}
}
