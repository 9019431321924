import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';

import { EmployeeComponent } from './employee/employee.component';
import { TeamComponent } from './team/team.component';
import { SettingsComponent } from './settings/settings.component';
import { ProfileComponent } from './profile/profile.component';

import { ExportComponent } from './export/export.component';
import { NewApplicationComponent } from './new-application/new-application.component';
import { BulkApplicationComponent } from './bulk-application/bulk-application.component';
import {AuthGuard} from './auth.guard';

import { ClientComponent } from './client/client.component';
import { ApplicationComponent } from './application/application.component';
import { ChecksComponent } from './checks/checks.component';
import { LoginComponent } from './user-pages/login/login.component';
import { InviteComponent } from './invite/invite.component';
import { ReportComponent } from './report/report.component';
import { ClientMisComponent } from './client-mis/client-mis.component';
import { TaskLogComponent } from './task-log/task-log.component';
import { ReportLogComponent } from './report-log/report-log.component';
import { InsufComponent } from './insuf/insuf.component';
import { InsufclearedComponent } from './insufcleared/insufcleared.component';

const routes: Routes = [
  { path: '',   redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent},
  { path: 'dashboard', component: DashboardComponent,canActivate:[AuthGuard]},
  { path: 'client', component: ClientComponent,canActivate:[AuthGuard]},
  { path: 'client/:id', component: ClientComponent,canActivate:[AuthGuard]},
  { path: 'employee', component: EmployeeComponent,canActivate:[AuthGuard]},
  { path: 'employee/:id', component: EmployeeComponent,canActivate:[AuthGuard]},
  { path: 'team', component: TeamComponent,canActivate:[AuthGuard]},
  { path: 'team/:id', component: TeamComponent,canActivate:[AuthGuard]},
  { path: 'profile', component: ProfileComponent },
  { path: 'setting', component: SettingsComponent,canActivate:[AuthGuard]},
  { path: 'export', component: ExportComponent},
  { path: 'new-application', component: NewApplicationComponent,canActivate:[AuthGuard]},
  { path: 'new-application/:id', component: NewApplicationComponent,canActivate:[AuthGuard]},
  { path: 'application', component: ApplicationComponent,canActivate:[AuthGuard]},
  { path: 'checks', component: ChecksComponent,canActivate:[AuthGuard]},
  { path: 'invite/:id', component: InviteComponent},
  { path: 'bulk-application', component: BulkApplicationComponent },
  { path: 'report', component: ReportComponent },
  { path: 'client-mis', component: ClientMisComponent },
  { path: 'task-log', component: TaskLogComponent },
  { path: 'report-log/:id', component: ReportLogComponent },
  { path: 'in-suf', component: InsufComponent },
  { path: 'in-suf-cleared', component: InsufclearedComponent },


  { path: 'basic-ui', loadChildren: () => import('./basic-ui/basic-ui.module').then(m => m.BasicUiModule) },
  { path: 'charts', loadChildren: () => import('./charts/charts.module').then(m => m.ChartsDemoModule) },
  { path: 'forms', loadChildren: () => import('./forms/form.module').then(m => m.FormModule) },
  { path: 'tables', loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule) },
  { path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule) },
  { path: 'general-pages', loadChildren: () => import('./general-pages/general-pages.module').then(m => m.GeneralPagesModule) },
  { path: 'apps', loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule) },
  { path: 'user-pages', loadChildren: () => import('./user-pages/user-pages.module').then(m => m.UserPagesModule) },
  { path: 'error-pages', loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
